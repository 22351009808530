import {api} from './api';

export const getBhaReportsApi = (plataforma: string | null, starttime: string, endtime: string) => {
    return api.get('bha_reports/', {params:{plataforma, starttime, endtime}})
}

export const getCasingReportsApi = (plataforma: string | null, starttime: string, endtime: string) => {
    return api.get('casing_reports/', {params:{plataforma, starttime, endtime}})
}

export const getRiserReportsApi = (plataforma: string | null, starttime: string, endtime: string) => {
    return api.get('riser_reports/', {params:{plataforma, starttime, endtime}})
}

export const getTimeReportsApi = (plataforma: string | null, starttime: string, endtime: string) => {
    return api.get('time_reports/', {params:{plataforma, starttime, endtime}})
}

export const getMetabaseReportsApi = (plataforma: string | null, starttime: string, endtime: string) => {
    return api.get('metabase_reports/', {params:{plataforma, starttime, endtime}})
}