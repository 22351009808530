import React, { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './DrillingMechanicsStyle.scss';
import { FormControl, InputLabel, Select, MenuItem, TextField, Button, ThemeProvider } from '@mui/material';
import moment from 'moment';
import { appTheme } from '../../styles/tema';
import { getstatusLogin } from '../../services/drillingMechanics';

const DrillingMechanics: React.FC = () => {

    const whitecolor = "#FFF"

    const plataformId = ["ddr8exx6130u8c", "adr8f1oddq8sgd"] as const;
    type PlataformType = typeof plataformId[number]

    const plataformTypeAliases: Record<PlataformType, string> = {
        "ddr8exx6130u8c": "PAE-003",
        "adr8f1oddq8sgd": "PAE-004"
    };

    const [selectedPlataformType, setSelectedPlataformType] = React.useState<string>(plataformId[0])

    const [startdate, setStartdate] = React.useState<string>(moment().format('YYYY-MM-DDTHH:mm'))
    const [enddate, setEnddate] = React.useState<string>(moment().format('YYYY-MM-DDTHH:mm'))

    const starttime = moment(startdate).valueOf()
    const endtime = moment(enddate).valueOf()

    const [showIframe, setShowIframe] = useState(false);

    const url = "https://xpd.grafana.panamerica.rzxtecnologia.com.br/d-solo/ddqggqtmtet4wb/drillingmechanics-panamerica?orgId=1&"

    const toggleIframe = () => {
        setShowIframe(!showIframe);
    };

    const getReports = () => {
        toggleIframe();
    }

    // Utilizado apenas para validar status do usuario no backend
    const getSelecao = () => {
        getstatusLogin().then((res) => { });
    };

    useEffect(() => {
        getSelecao();
    }, []);

    return (
        <ThemeProvider theme={appTheme} >
            <div className="reports vh-100">
                <NavBar />
                <div className="d-flex text-white pl-3 py-1">
                    XPD {'>'} Drilling Mechanics
                </div>
                <div className="d-flex">
                    <div className="d-flex flex-1 vh-80 flex-column">
                        <div className='d-flex w-100 p-2 text-white bg-cinza' style={{ alignItems: "center", placeContent: "center" }}>

                            <TextField
                                id="datetime-local"
                                label="Start Date"
                                type="datetime-local"
                                value={startdate}
                                onChange={(e) => { setStartdate(e.target.value) }}
                                sx={{
                                    svg: { color: whitecolor },
                                    input: { color: whitecolor },
                                    label: { color: whitecolor },
                                    m: 2
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <TextField
                                id="datetime-local"
                                label="End Date"
                                type="datetime-local"
                                value={enddate}
                                onChange={(e) => { setEnddate(e.target.value) }}
                                sx={{
                                    svg: { color: whitecolor },
                                    input: { color: whitecolor },
                                    label: { color: whitecolor },
                                    m: 2
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <FormControl sx={{ minWidth: 200, m: 2 }}>
                                <InputLabel id="plataformType" sx={{ color: '#FFF' }}>Plataform</InputLabel>
                                <Select
                                    labelId="plataformType"
                                    id="plataformTypeSelect"
                                    value={selectedPlataformType}
                                    label="Plataform"
                                    onChange={(e) => { setSelectedPlataformType(e.target.value as PlataformType) }}
                                    sx={{
                                        svg: { color: whitecolor }
                                    }}
                                    style={{ color: whitecolor }}
                                >
                                    {plataformId.map(plataformType => (
                                        <MenuItem value={plataformType} key={plataformType}>{plataformTypeAliases[plataformType]}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button variant="contained" onClick={() => { getReports() }}>
                                Find
                            </Button>
                        </div>

                        <div className="iframe-container">
                            {showIframe && <iframe
                                className="grid"
                                src={`${url}var-FonteDeDados=${selectedPlataformType}&from=${starttime}&to=${endtime}&panelId=8`}
                                title="Drilling Mechanics"
                            ></iframe>}
                            {showIframe && <iframe
                                className="grid"
                                src={`${url}var-FonteDeDados=${selectedPlataformType}&from=${starttime}&to=${endtime}&panelId=9`}
                                title="Drilling Mechanics"
                            ></iframe>}
                            {showIframe && <iframe
                                className="grid"
                                src={`${url}var-FonteDeDados=${selectedPlataformType}&from=${starttime}&to=${endtime}&panelId=12`}
                                title="Drilling Mechanics"
                            ></iframe>}
                            {showIframe && <iframe
                                className="grid"
                                src={`${url}var-FonteDeDados=${selectedPlataformType}&from=${starttime}&to=${endtime}&panelId=11`}
                                title="Drilling Mechanics"
                            ></iframe>}
                            {showIframe && <iframe
                                className="grid"
                                src={`${url}var-FonteDeDados=${selectedPlataformType}&from=${starttime}&to=${endtime}&panelId=13`}
                                title="Drilling Mechanics"
                            ></iframe>}
                            {showIframe && <iframe
                                className="grid"
                                src={`${url}var-FonteDeDados=${selectedPlataformType}&from=${starttime}&to=${endtime}&panelId=7`}
                                title="Drilling Mechanics"
                            ></iframe>}
                            {showIframe && <iframe
                                className="grid"
                                src={`${url}var-FonteDeDados=${selectedPlataformType}&from=${starttime}&to=${endtime}&panelId=15`}
                                title="Drilling Mechanics"
                            ></iframe>}
                            {showIframe && <iframe
                                className="grid"
                                src={`${url}var-FonteDeDados=${selectedPlataformType}&from=${starttime}&to=${endtime}&panelId=14`}
                                title="Drilling Mechanics"
                            ></iframe>}

                        </div>

                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default DrillingMechanics